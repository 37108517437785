function navIsScrolled(anchorNav) {
    const NAV = anchorNav.querySelector('.nav');
    if (NAV) return NAV.getBoundingClientRect().x < 16;
    else return false;
}

function toggleScrolledAnchorNav(anchorNav) {
    if (navIsScrolled(anchorNav)) anchorNav.setAttribute('data-scrolled', '');
    else anchorNav.removeAttribute('data-scrolled');
}

export function INIT_ANCHOR_NAV(anchorNav, menuToggleCheckbox) {

    anchorNav.querySelectorAll('.nav-link').forEach(link => {
        link.addEventListener('click', () => {
            if (menuToggleCheckbox.checked) menuToggleCheckbox.checked = false;
        });
    });

    anchorNav.addEventListener('scroll', () => toggleScrolledAnchorNav(anchorNav));
}

import {CHECK_JS} from "./js/components/init-js-check";
import {INIT_FANCYBOX} from "./js/components/init-fancybox";
import {INIT_COOKIE_MANAGER} from "./js/ccm/init.ccm";
import {INIT_SCROLL_TOP} from "./js/components/init-scroll-top";
import {INIT_SCROLLSPY} from "./js/components/init-scrollspy";
import {INIT_ANCHOR_NAV} from "./js/components/init-anchor-nav";
import {INIT_SMOOTH_SCROLL} from "./js/components/init-smooth-scroll";
import {INIT_HEADROOM} from "./js/components/init-headroom";
import {INIT_SEARCH_HEADER} from "./js/components/init-search-header";
import {INIT_POPOVER} from "./js/components/init-popover";

document.addEventListener('DOMContentLoaded', () => {
    CHECK_JS();
    INIT_FANCYBOX();
    INIT_COOKIE_MANAGER();
    INIT_POPOVER();

    if (document.querySelector('.is--to-top')) INIT_SCROLL_TOP(document.querySelector('.is--to-top'));
    if (document.querySelector('#anchorNavigation')) {
        INIT_SCROLLSPY(document.querySelector('#anchorNavigation'));
        if (document.getElementById('menuToggleCheckbox')) INIT_ANCHOR_NAV(document.querySelector('#anchorNavigation'), document.getElementById('menuToggleCheckbox'));
    }
    if (document.querySelector('header.header')) {
        INIT_SMOOTH_SCROLL(document.querySelector('header.header'));
        INIT_HEADROOM(document.querySelector('header.header'));
    }
    if (document.querySelector('[data-search-header]')) INIT_SEARCH_HEADER(document.querySelector('[data-search-header]'));
});

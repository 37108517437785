import SmoothScroll from "smooth-scroll";

function setSmoothsScroll(header, speedInMs = 50, speedAsDuration = false) {
    new SmoothScroll('a[href*="#"]', {
        speed: speedInMs,
        speedAsDuration: speedAsDuration,
        offset: header.clientHeight + 32
    });
}

function checkBreakpointAndAddSmoothScroll(header) {
    if (window.matchMedia('(min-width: 720px)').matches) setSmoothsScroll(header);
    else setSmoothsScroll(header, 500, true);
}

export function INIT_SMOOTH_SCROLL(header) {
    checkBreakpointAndAddSmoothScroll(header)
    window.addEventListener('resize', () => checkBreakpointAndAddSmoothScroll(header));
}

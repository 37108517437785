import Headroom from 'headroom.js';

export function INIT_HEADROOM(header) {
    const HEADROOM = new Headroom(header, {
        offset: 0,
        tolerance: {
            up: 5,
            down: 20
        }
    })
    HEADROOM.init();
}

import {ScrollSpy} from "bootstrap";

function scrollItem(anchorNav) {
    window.addEventListener('activate.bs.scrollspy', () => {
        const activeItemOffset = anchorNav.querySelector('.nav-link.active').offsetLeft;
        const headlineWidth = anchorNav.querySelector('.nav-link--headline').clientWidth;

        anchorNav.scroll({
            left: activeItemOffset - (headlineWidth * 1.25),
            top: 0,
            behavior: 'smooth'
        });
    });
}

export function INIT_SCROLLSPY(anchorNav) {
    new ScrollSpy(document.body, {
        target: anchorNav,
        offset: document.documentElement.clientHeight / 2
    });
    scrollItem(anchorNav)
}



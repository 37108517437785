function formIsActive(searchForm) {
    return !!searchForm.getAttribute('data-active');
}

export function INIT_SEARCH_HEADER(searchForm) {

    const FORM_INPUT = searchForm.querySelector('#searchHeader');

    searchForm.addEventListener('submit', e => {
        if (!formIsActive(searchForm)) e.preventDefault();
    });

    searchForm.querySelector('button[type="submit"]').addEventListener('click', e => {

        if (!formIsActive(searchForm)) {
            e.preventDefault();
            searchForm.setAttribute('data-active', 'true');
            FORM_INPUT.focus();
        } else {
            if (!!!FORM_INPUT.value) {
                e.preventDefault();
                searchForm.removeAttribute('data-active');
            }

        }
    })

    document.addEventListener('click', e => {
        const FORM_HAS_VALUE = !!FORM_INPUT.value,
            NO_CLICK_TARGET = e.composedPath().indexOf(searchForm) === -1;

        if (NO_CLICK_TARGET && !FORM_HAS_VALUE) searchForm.removeAttribute('data-active');
    })

}

import {INIT_NEW_INLINE_CCM_CONTENT} from "./inline-consent";

const KLARO_DOMAIN = window.location.hostname.match(/\./g).length > 1 ? window.location.hostname.substring(window.location.hostname.indexOf('.') + 1) : window.location.hostname;
const TABLE_HEAD = `
<thead>
<tr>
    <th>Name</th>
    <th>Anbieter</th>
    <th>Lebensdauer</th>
    <th>Beschreibung</th>
  </tr>
  </thead>
`;

const CCM_COOKIE_TABLE = `<table class="table my-1">
    ${TABLE_HEAD}
  <tbody>
  <tr>
    <td>Ccm.knw.de</td>
    <td>Klaro</td>
    <td>4 Monate</td>
    <td>Dieser Cookie wird verwendet, um Einwilligungen der Webseitennutzer zur Datenverarbeitung einzuholen und zu verwalten.</td>
  </tr>
  </tbody>
</table>`;
const GOOGLE_COOKIE_TABLE = `<table class="table my-1">
    ${TABLE_HEAD}
  <tbody>
  <tr>
    <td>_ga</td>
    <td>Google Analytics</td>
    <td>24 Monate</td>
    <td>Dieser Cookie wird verwendet, um Statistiken über die Nutzung der Website zu erheben (Reichweitenmessung).</td>
  </tr>
  <tr>
    <td>_ga_EQCOTOKPJ6</td>
    <td>Google Analytics</td>
    <td>24 Monate</td>
    <td>Dieser Cookie wird verwendet, um Statistiken über die Nutzung der Website zu erheben (Reichweitenmessung).</td>
  </tr>
  </tbody>
</table>`;
const MAPBOX_COOKIE_TABLE = `<table class="table my-1">
    ${TABLE_HEAD}
  <tbody>
  <tr>
    <td>Mapbox</td>
    <td>Mapbox Inc.</td>
    <td>unbegrenzt</td>
    <td>Beschreibung: Dieser Cookie wird verwendet, um interaktive (Land-)Karten dazustellen und eine komfortable Nutzung der Karten-Funktion zu ermöglichen.</td>
  </tr>
  </tbody>
</table>`;
const YOUTUBE_COOKIE_TABLE = `<table class="table my-1">
    ${TABLE_HEAD}
  <tbody>
  <tr>
    <td>VISITOR-INFO1-LIVE</td>
    <td>YouTube</td>
    <td>6 Monate</td>
    <td>Dieser Cookie wird verwendet, um die Bandbreite auf Seiten mit integrierten YouTube-Videos zu schätzen.</td>
  </tr>
  <tr>
    <td>YSC</td>
    <td>YouTube</td>
    <td>Sitzungsende</td>
    <td>Registriert eine eindeutige ID, um Statistiken der Videos von YouTube, die der Benutzer gesehen hat, zu behalten.</td>
  </tr>
  </tbody>
</table>`;

const CCM_DESCRIPTION = `
<strong>Wir verwenden Cookies und andere Technologien auf unserer Website.</strong>
Diese werden auf Ihrem Gerät oder in Ihrem Browser gespeichert und lesen u.a. personenbezogene Daten wie bspw. die
IP-Adresse oder andere eindeutige Identifikationsmerkmale, aus.<br><br>
Zwingend erforderliche Cookies und vergleichbare Technologien (im Folgenden einheitlich \"Cookies\") gewährleisten
grundlegende Funktionen der Website und kommen ohne Einwilligung zum Einsatz.
<strong>Alle anderen Cookies sind optionaler Natur.</strong>
Analyse / Statistik Cookies erfassen anonyme Informationen darüber, wie die Website genutzt wird. Die erfassten
Informationen können mit weiteren Daten (wie z.B. Geschlecht, Altersdekade und PLZ-Bereich) zusammengefasst werden, um
Analysen zur Websitenutzung zu erstellen. Präferenzen Cookies dienen dazu, Ihre getroffene Auswahl z.B. in Bezug auf
Sprache oder Region zu speichern und Sie bei erneutem Besuch der Seite als Nutzer zu erkennen.
`;
export const KLARO_CONFIG = {
    cookieName: 'ccm.knw.de',
    noAutoLoad: false,
    htmlTexts: true,
    embedded: false,
    groupByPurpose: true,
    cookieExpiresAfterDays: 120,
    default: false,
    mustConsent: false,
    acceptAll: true,
    hideDeclineAll: false,
    hideLearnMore: false,
    noticeAsModal: false,
    disablePoweredBy: true,

    translations: {
        de: {
            privacyPolicyUrl: '/datenschutz',
            close: 'Schließen',
            decline: 'Notwendige erlauben',
            ok: 'Alle akzeptieren',
            save: 'Speichern',

            consentModal: {
                title: 'Einstellungen zum Datenschutz',
                description: CCM_DESCRIPTION,
                inlineDescription: "Ich bin damit einverstanden, dass mir dieser externe Inhalt angezeigt wird. Personenbezogene Daten können an Drittplattformen übermittelt werden. Mehr dazu in unserer {privacyPolicy}."
            },

            privacyPolicy: {
                name: 'Datenschutzerklärung',
                text: 'Weitere Informationen über die Verwendung Ihrer Daten finden Sie in unserer {privacyPolicy}. <br><br> <strong>Sie können Ihre Einstellungen jederzeit anpassen sowie Ihre Einwilligung widerrufen, indem Sie im Footer der Webseite "Cookies" anklicken.</strong>'
            },

            consentNotice: {
                changeDescription: 'Es gab Änderungen seit Ihrem letzten Besuch, bitte aktualisieren Sie Ihre Auswahl.',
                description: '<strong>Wir verwenden Cookies und andere Technologien auf unserer Website. Diese werden auf Ihrem Gerät oder in Ihrem Browser gespeichert und lesen u.a. personenbezogene Daten wie bspw. die IP-Adresse oder andere eindeutige Identifikationsmerkmale, aus.</strong> Die Einstellungen können jederzeit angepasst oder alle Cookies akzeptiert werden.',
                learnMore: 'Anpassen'
            },

            acceptSelected: 'Auswahl speichern',
            acceptAll: 'Alle akzeptieren',

            service: {
                disableAll: {
                    title: 'Alle Dienste aktivieren oder deaktivieren',
                    description: 'Mit diesem Schalter alle Anwendungen aktivieren/deaktivieren.'
                },
                required: {
                    title: ' (erforderlich)',
                    description: 'required description'
                },
                purpose: 'Zweck',
                purposes: 'Zwecke'
            },

            contextualConsent: {
                headline: 'Empfohlener externer Inhalt',
                acceptAlways: 'Immer laden',
                acceptOnce: 'Akzeptieren und Inhalte laden',
                description: 'Möchten Sie von {title} bereitgestellte externe Inhalte laden?'
            },

            purposeItem: {
                service: 'Dienst',
                services: 'Dienste'
            },

            purposes: {
                required: {
                    title: 'Technisch notwendig',
                    description: 'Diese Cookies sind für eine gute Funktionalität unserer Website erforderlich und können in unserem System nicht ausgeschaltet werden.'
                },
                marketing: {
                    title: 'Marketing',
                    description: 'Durch Marketing Cookies können wir die individuelle Nutzererfahrung unserer Website stetig verbessern'
                },
                functional: {
                    title: 'Präferenz',
                    description: 'Wir verwenden diese Cookies, um die Funktionalität zu verbessern und die Personalisierung zu ermöglichen.'
                },
                analytics: {
                    title: 'Analyse',
                    description: 'Wir verwenden diese Cookies, um statistische Informationen über unsere Website und Besucher anonymisiert bereitzustellen. Sie werden zur Leistungsmessung und -verbesserung verwendet.'
                }
            },

            cookieManager: {
                description: 'Für die Einstellung dieses Cookie-Managers wird ein Cookie gesetzt. Dieser dient ausschließlich der Speicherung der Einstellungen wie sie getroffen wurden und ist demnach technisch erforderlich. <br>' + CCM_COOKIE_TABLE
            },
            googleAnalytics: {
                description: 'Um die Seite fortlaufend zu verbessern, analysieren wir das Verhalten der Besucher mit Google Analytics. <br>' + GOOGLE_COOKIE_TABLE
            },
            // vimeo: {
            //     description: 'Lorem Ipsum dolor sit amet.'
            // },
            youtube: {
                description: 'Zur Anzeige von Videos verwenden wir Youtube. <br>' + YOUTUBE_COOKIE_TABLE
            },
            mapbox: {
                description: 'Zur Geolokalisierung und zur Darstellung unserer Kita-Standorte auf einer interaktiven Karte, verwenden wir Mapbox. <br>' + MAPBOX_COOKIE_TABLE
            }
        }
    },

    services: [
        {
            name: 'cookieManager',
            title: 'Cookie Manager',
            purposes: ['required'],
            required: true
        },
        {
            name: 'googleAnalytics',
            title: 'Google Analytics',
            cookies: [
                ['_ga', '/', '.' + KLARO_DOMAIN],
                ['_gid', '/', '.' + KLARO_DOMAIN],
                [/^_ga_.*$/i, '/', '.' + KLARO_DOMAIN],
                [/^_gat_.*$/i, '/', '.' + KLARO_DOMAIN],
                [/^_gac_.*$/i, '/', '.' + KLARO_DOMAIN],
                [/^_gac_.*$/i, '/', '.' + KLARO_DOMAIN]
            ],
            purposes: ['analytics']
        },
        // {
        //     name: 'vimeo',
        //     purposes: ['marketing']
        // },
        {
            name: 'youtube',
            purposes: ['marketing']
        },
        {
            name: 'mapbox',
            purposes: ['functional']
        }
    ],
};

